<template>
    <div>
        <b-row class="my-2">
            <b-col sm="8">
                <b>ASSET TYPE CODES</b>
            </b-col>
        </b-row>

        <b-row class="mt-3" no-gutters>
            <b-col sm="10" md="5">
                <b-form-group label="Prefix" label-for="Prefix" description="ALLOWED VALUE: Up to 4 capital letters.">
                    <b-form-input name="Prefix" type="text" v-model="prefix" v-validate="{
                        regex: prefixRegex,
                    }" maxlength="4" />
                    <span v-show="errors.has('Prefix')" class="help-block">{{
                        errors.first('Prefix')
                    }}</span>
                </b-form-group>
            </b-col>
            <b-col sm="1">
                <b-button class="add-code" variant="success" @click="onAddAssetTypeCode">Add</b-button>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="form.assetTypeCodes" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(action)="row">
                <b-button v-if="!row.item.id || row.item.id.length === 0" size="sm" v-b-tooltip.hover.top="'Remove'"
                    variant="danger" @click="onRemoveAssetTypeCode(row.item)" class="mr-1">
                    <i class="fa fa-trash-o"></i>
                </b-button>
                <span v-else><i>Can't remove an existing code</i></span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Util
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import AssetTypeCodesDAO from '@/database/assetTypeCodes';

// Others
import _ from 'lodash';

export default {
    name: 'asset-type-codes',
    props: {
        form: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            // Asset Type Codes
            prefix: '',
            fields: [
                {
                    key: 'codeName',
                    label: 'Series Name',
                    sortable: true,
                },
                {
                    key: 'prefix',
                },
                'action',
            ],

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    watch: {
        prefix: function (newVal) {
            if (newVal.length > 0) {
                this.prefix = ValidationUtil.removeExcessWhiteSpace(newVal);
            }
        }
    },
    computed: {
        prefixRegex() {
            return /^([A-Z]{1,4})$/;
        },
        totalRows() {
            return this.form.assetTypeCodes.length
        }
    },
    methods: {
        async onAddAssetTypeCode() {
            let isValid = await this.validateAssetTypeCode();
            if (!isValid) {
                return;
            }

            this.form.assetTypeCodes.push({
                prefix: this.prefix,
                codeName: AssetTypeUtil.getCodeName(this.prefix),
            });

            // reset field
            this.prefix = '';
        },
        async validateAssetTypeCode() {
            let isValid = true;

            if (_.isEmpty(this.prefix)) {
                this.$toaster.warning('Prefix is required.');
                isValid = false;
            } else {
                let isExisting = await this.hasExistingAssetTypeCode();
                if (isExisting) {
                    this.$toaster.warning('Asset type code already exists on this asset type or other asset type(s).');
                    isValid = false;
                } else if (!this.prefixRegex.test(this.prefix)) {
                    this.$toaster.warning('Invalid prefix format. Up to 4 capital letters only.');
                    isValid = false;
                }
            }

            return isValid;
        },
        async hasExistingAssetTypeCode() {
            const uniqueValues = {
                prefix: this.prefix,
            };

            let exists = _.some(
                Object.values(this.form.assetTypeCodes),
                uniqueValues
            );
            if (exists) {
                return exists;
            } else {
                let assetTypeCodeObj = await AssetTypeCodesDAO.getAssetTypeCodeByPrefix(
                    this.prefix
                );
                return !_.isEmpty(assetTypeCodeObj);
            }
        },

        onRemoveAssetTypeCode(assetTypeCode) {
            let index = _.findIndex(this.form.assetTypeCodes, (item) => {
                return item.prefix == assetTypeCode.prefix;
            });

            if (index >= 0) {
                this.form.assetTypeCodes = _.filter(this.form.assetTypeCodes, (item) => {
                    return item.prefix !== assetTypeCode.prefix;
                });
            }
        },
    }
}
</script>

<style>
.add-code {
    margin-top: 29px;
    margin-left: 10px;
}

.pagination {
    margin-top: 29px;
}
</style>