<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-form-group label="Name" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span :class="{ truncate: row.item.name.length > 50 }">
							{{ row.item.name }}
						</span>
					</b-form-group>

					<b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.description" />
						</span>
					</b-form-group>

					<b-form-group label="Asset Tagging" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.assetTagging ? row.item.assetTagging : 'Default' }}
					</b-form-group>

					<b-form-group v-if="row.item.assetTagging === 'Default'" label="Category" label-cols-sm="3"
						label-class="font-weight-bold pt-0" label-align-sm="right">
						<span :class="{ truncate: row.item.type.length > 50 }">
							{{ showValue(row.item.type) }}
						</span>
					</b-form-group>
					<b-form-group v-else label="Has Categories?" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.hasCategories ? 'YES' : 'NO' }}
					</b-form-group>

					<b-form-group label="Asset Type Owner" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.origin }}
					</b-form-group>

					<b-form-group label="No. of Stickers per Asset" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.noOfStickers }}
						</span>
					</b-form-group>

					<b-form-group label="Unit of Measure (UOM)" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.uom ? row.item.uom : "-" }}
					</b-form-group>

					<b-form-group label="Unit Cost" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.unitCost ? 'PHP ' + row.item.unitCost.toLocaleString("en-US") : "-" }}
						</span>
					</b-form-group>

					<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-tab>

				<b-tab title="Conditions">
					<b-col sm="12" class="mt-2">
						<AssetTypeConditionsDetailsView :row="row" />
					</b-col>
				</b-tab>

				<b-tab title="Asset Tagging" v-if="row.item.assetTagging !== 'Custom'">
					<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
					<b-col sm="12" class="mt-2">
						<AssetTypeCodesDetailsView :row="row" />
					</b-col>

					<b-col sm="12" v-show="getAssetTypePoolCount(row.item.id) > 0">
						<b-col sm="12" class="md-left-text-sm-right">
							<b>ASSET LOCATION SUMMARY</b>
						</b-col>
						<b-col sm="12" class="my-1">
							<b-table show-empty striped hover :items="assetTypePools" :fields="assetTypePoolsFields" />
						</b-col>
					</b-col>
				</b-tab>
				<b-tab title="Asset Tagging" v-else>
					<b-form-group label="Label Tag Format:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						/{{ row.item.regex }}/
					</b-form-group>

					<b-form-group label="Test Values:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<b-badge pill variant="success">{{
							row.item.testValues[0]
						}}</b-badge>
						&nbsp;
						<b-badge pill variant="success">{{
							row.item.testValues[1]
						}}</b-badge>
						&nbsp;
						<b-badge pill variant="success">{{
							row.item.testValues[2]
						}}</b-badge>
						&nbsp;
					</b-form-group>
				</b-tab>

				<b-tab title="Categories" v-if="row.item.hasCategories">
					<b-col sm="12" class="mt-2">
						<AssetTypeCategoriesDetailsView :row="row" />
					</b-col>
				</b-tab>

				<b-tab title="Change Logs">
					<b-col sm="12" class="mt-2">
						<AssetTypeChangeLogsDetailsView :row="row" />
					</b-col>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// Components
import AssetTypeConditionsDetailsView from './AssetTypeConditionsDetailsView';
import AssetTypeCategoriesDetailsView from './AssetTypeCategoriesDetailsView';
import AssetTypeCodesDetailsView from './AssetTypeCodesDetailsView';
import AssetTypeChangeLogsDetailsView from './AssetTypeChangeLogsDetailsView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { SortUtil } from '@/utils/sortUtil';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'asset-type-details-view',
	components: {
		AssetTypeConditionsDetailsView,
		AssetTypeCategoriesDetailsView,
		AssetTypeCodesDetailsView,
		AssetTypeChangeLogsDetailsView,
		Loading,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		assetTypeDistribution: {
			type: Object,
			required: true,
		},
		assetLocationDistribution: {
			type: Object,
			required: true,
		},
		storageLocationsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			assetTypeCodesFields: [
				{
					key: 'codeName',
					label: 'Series Name',
				},
				{
					key: 'prefix',
				},
			],
			assetTypePools: [],
			assetTypePoolsFields: [
				{
					key: 'company',
				},
				{
					key: 'storageLocation',
				},
				{
					key: 'count',
					label: 'Total',
				},
			],
			fields: [
				'dateUpdated',
				'changes',
				{ key: 'reasonForUpdate', label: 'Reason' },
				'updatedBy',
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// Check for loader
			isLoading: false,
		};
	},
	async mounted() {
		// Show loading indicator
		this.isLoading = true;

		// retrieve asset type pool
		let assetTypeId = this.row.item.id;
		this.assetTypePools = this.getAssetTypePool(assetTypeId);

		// Hide loading indicator
		this.isLoading = false;
	},
	methods: {
		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getAssetTypePoolCount(assetTypeId) {
			let totalCount = 0;
			if (this.assetTypeDistribution[assetTypeId]) {
				totalCount = this.assetTypeDistribution[assetTypeId].totalQuantity;
			}
			return totalCount;
		},
		getAssetTypePool(assetTypeId) {
			let vm = this;

			let companyDistribution = [];

			if (this.assetLocationDistribution) {
				let locIds = Object.keys(this.assetLocationDistribution);

				locIds.forEach((locId) => {
					let location = vm.storageLocationsObj[locId];
					let distribution = vm.assetLocationDistribution[locId];
					if (
						distribution.assetTypes[assetTypeId] &&
						distribution.assetTypes[assetTypeId].groupCount > 0
					) {
						companyDistribution.push({
							locId: locId,
							company: location.company,
							storageLocation: location.name,
							count: distribution.assetTypes[assetTypeId].groupCount,
						});
					}
				});
			}

			companyDistribution = SortUtil.sortByAttr('company', companyDistribution);
			return companyDistribution;
		}
	},
};
</script>

<style scoped>
.truncate {
	width: 300px;
	display: inline-block;
}
</style>
