<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">ASSET TYPE CODES</span>
                <div class="details-view-subtitle mb-2">List of code series used for asset tagging</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="assetTypeCodes" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive />

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// API & Database
import assetTypeApi from '@/api/assetTypeApi';


export default {
    name: 'asset-type-codes-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            assetTypeCodes: [],
            fields: [
                {
                    key: 'codeName',
                    label: 'Series Name',
                },
                {
                    key: 'prefix',
                },
            ],

            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            loggedUser: this.$store.getters.loggedUser,
        }
    },
    async mounted() {
        // retrieve asset type codes
        let assetTypeId = this.row.item.id;
        if (this.row.item.assetTagging === 'Default') {
            let { data } = await assetTypeApi.getAssetType({
                currUserId: this.loggedUser.id,
                assetTypeId: assetTypeId,
            });
            this.assetTypeCodes = data.assetTypeCodes;
        }
    }
}
</script>