<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>
            <template v-slot:cell(changes)="row">
                <!-- Primary Information -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'noOfStickers')">
                    <b>Number of Stickers per Asset</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'noOfStickers') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'noOfStickers') }} </b>
                </div>

                <!-- Inventory Details -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'uom')">
                    <b>Unit of Measure (UOM)</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'uom') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'uom') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'unitCost')">
                    <b>Unit Cost</b>
                    <br />
                    From <b class="old-value"> {{ getOldValue(row.item, 'unitCost') }} PHP</b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'unitCost') }} PHP</b>
                </div>

                <!-- Conditions -->
                <div class="changed-section mb-2" v-if="hasChangesInArrayItems(row.item, 'conditions')">
                    <b>Condition</b>
                    <div v-show="getAddedArrayItems(row.item, 'conditions', 'condition').length > 0">
                        Added <b><span class="new-value">{{ getAddedArrayItems(row.item, 'conditions', 'condition') }}</span></b>
                    </div>
                    <div v-show="getRemovedArrayItems(row.item, 'conditions', 'condition').length > 0">
                        Removed <b><span class="old-value">{{ getRemovedArrayItems(row.item, 'conditions', 'condition') }}</span></b>
                    </div>
                </div>

                <!-- FOR CUSTOM Asset Tagging -->
                <div v-if="row.item.new.assetTagging === 'Custom'">
                    <div class="changed-section" v-if="hasChangesInField(row.item, 'hasCategories')">
                        <b>Has Categories?</b>
                        <br>
                        From <b class="old-value"> {{ getOldValue(row.item, 'hasCategories') === true ? 'Yes' : 'No' }}
                        </b>
                        to <b class="new-value"> {{ getNewValue(row.item, 'hasCategories') === true ? 'Yes' : 'No' }}
                        </b>
                    </div>
                    <div class="changed-section mb-2" v-if="hasChangesInArrayItems(row.item, 'categories')">
                        <b>Categories</b>
                        <div v-show="getAddedArrayItems(row.item, 'categories', 'name').length > 0">
                            Added <b><span class="new-value">{{ getAddedArrayItems(row.item, 'categories', 'name') }}</span></b>
                        </div>
                        <div v-show="getRemovedArrayItems(row.item, 'categories', 'name').length > 0">
                            Removed <b><span class="old-value">{{ getRemovedArrayItems(row.item, 'categories', 'name') }}</span></b>
                        </div>
                    </div>
                    <div class="changed-section" v-if="hasChangesInField(row.item, 'regex')">
                        <b>Regex</b>
                        <br>
                        From <b class="old-value"> {{ getOldValue(row.item, 'regex') }} </b>
                        to <b class="new-value"> {{ getNewValue(row.item, 'regex') }} </b>
                    </div>
                </div>

                <!-- Subscription -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <AssetTypeStatus :assetType="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <AssetTypeStatus :assetType="row.item.new" />
                </div>

            </template>
            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>
        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary"
                    v-b-tooltip.hover.top="'Hide Details'">
                    <i class="icon-arrow-up"></i>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Components
import AssetTypeStatus from '@/views/setup/assetType/AssetTypeStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';


export default {
    name: 'asset-type-change-logs-details-view',
    components: {
        AssetTypeStatus
    },
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            assetTypeId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        };
    },
    watch: {
        assetTypeId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.assetTypeId = this.row.item.id;
        await this.retrieveChangeLog(this.assetTypeId);
    },
    methods: {
        async retrieveChangeLog(assetTypeId) {
            if (assetTypeId) {
                let param = {
                    collection: 'assetTypes',
                    id: assetTypeId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },

        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, (log) => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Description
                const oldDesc = oldLog.description ? oldLog.description : '';
                const newDesc = newLog.description ? newLog.description : '';
                // No. of Stickers
                const oldNoOfStickers = oldLog.noOfStickers ? oldLog.noOfStickers : 0;
                const newNoOfStickers = newLog.noOfStickers ? newLog.noOfStickers : 0;
                // Unit Cost
                const oldUnitCost = oldLog.unitCost ? oldLog.unitCost : 0;
                const newUnitCost = newLog.unitCost ? newLog.unitCost : 0;
                // Conditions
                const addedCondition = this.getAddedItems(oldLog.conditions, newLog.conditions).length;
                const removedCondition = this.getRemovedItems(oldLog.conditions, newLog.conditions).length;
                // Regex - Custom Asset Tag
                const oldRegex = oldLog.regex ? oldLog.regex : '';
                const newRegex = newLog.regex ? newLog.regex : '';
                // Categories - Custom Asset Tag
                const addedCategories = this.getAddedItems(oldLog.categories, newLog.categories).length;
                const removedCategories = this.getRemovedItems(oldLog.categories, newLog.categories).length;

                return oldDesc !== newDesc ||
                    oldNoOfStickers !== newNoOfStickers ||
                    oldUnitCost !== newUnitCost ||
                    addedCondition > 0 ||
                    removedCondition > 0 ||
                    oldRegex !== newRegex ||
                    addedCategories > 0 ||
                    removedCategories > 0;
            });
            this.changeLogs = filteredLogs;
        },

        hasChangesInField(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        hasChangesInArrayItems(log, fieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : [];
            const newArrayField = log.new ? log.new[fieldName] : [];

            let addedItems = this.getAddedItems(oldArrayField, newArrayField);
            let removedItems = this.getRemovedItems(oldArrayField, newArrayField);
            return !_.isEmpty(addedItems) || !_.isEmpty(removedItems);
        },
        getAddedArrayItems(log, fieldName, arrayFieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : {};
            const newArrayField = log.new ? log.new[fieldName] : {};

            let addedItems = this.getAddedItems(oldArrayField, newArrayField);
            return !_.isEmpty(addedItems) ? _.map(addedItems, arrayFieldName).join(", ") : "";
        },
        getRemovedArrayItems(log, fieldName, arrayFieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : {};
            const newArrayField = log.new ? log.new[fieldName] : {};

            let removedItems = this.getRemovedItems(oldArrayField, newArrayField);
            return !_.isEmpty(removedItems) ? _.map(removedItems, arrayFieldName).join() : "";
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getColorIndicator(oldObj, newObj) {
            const oldValue = oldObj && oldObj.unitCost ? oldObj.unitCost : 0;
            const newValue = newObj && newObj.unitCost ? newObj.unitCost : 0;

            if (oldValue === newValue)
                return '';
            else if (oldValue < newValue)
                return 'new-value'
            else return 'old-value';
        },
        getValue(toParseValue) {
            let value;
            if (typeof toParseValue === 'boolean') {
                value = toParseValue;
            } else {
                value = toParseValue ? toParseValue : "-";
            }
            return value
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            return this.getValue(oldLog[fieldName]);
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            return this.getValue(newLog[fieldName]);
        },
        getAddedItems(oldItems, newItems) {
            const compare = (oldItems, newItems) => oldItems.id === newItems.id;
            return _.differenceWith(newItems, oldItems, compare);
        },
        getRemovedItems(oldItems, newItems) {
            const compare = (oldItems, newItems) => oldItems.id === newItems.id;
            return _.differenceWith(oldItems, newItems, compare);
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>